// Font-face
// --------------------------------------------------------------------------------
@mixin font_face($font_name, $file_name, $font_subset: $font_name, $font_style: normal, $font_weight: normal)
{
	@font-face
	{
		font-family: quote($font_name);
		src: url(pws_file('fonts', $file_name) + '.eot');
		src: url(pws_file('fonts', $file_name) + '.eot?#iefix') format('embedded-opentype'),
			url(pws_file('fonts', $file_name) + '.woff2') format('woff2'),
			url(pws_file('fonts', $file_name) + '.woff') format('woff'),
			url(pws_file('fonts', $file_name) + '.ttf') format('truetype'),
			url(pws_file('fonts', $file_name) + '.svg#' + str-replace($font_subset, " ", "")) format('svg');
		font-style: $font_style;
		font-weight: $font_weight;
	}
}