// Open Sans Light
//@include font_face('Open Sans', 'OpenSans-Light', 'OpenSans', normal, 300);

// Open Sans Light Italic
//@include font_face('Open Sans', 'OpenSans-LightItalic', 'OpenSans', italic, 300);

// Open Sans Regular
@include font_face('Open Sans', 'OpenSans-Regular', 'OpenSans', normal, 400);

// Open Sans Italic
@include font_face('Open Sans', 'OpenSans-Italic', 'OpenSans', italic, 400);

// Open Sans Semi-Bold
//@include font_face('Open Sans', 'OpenSans-SemiBold', 'OpenSans', normal, 600);

// Open Sans Semi-Bold Italic
//@include font_face('Open Sans', 'OpenSans-SemiBoldItalic', 'OpenSans', italic, 600);

// Open Sans Bold
@include font_face('Open Sans', 'OpenSans-Bold', 'OpenSans', normal, 700);

// Open Sans Bold Italic
@include font_face('Open Sans', 'OpenSans-BoldItalic', 'OpenSans', italic, 700);

// Open Sans Extra-Bold
//@include font_face('Open Sans', 'OpenSans-ExtraBold', 'OpenSans', normal, 800);

// Open Sans Extra-Bold Italic
//@include font_face('Open Sans', 'OpenSans-ExtraBoldItalic', 'OpenSans', italic, 800);
