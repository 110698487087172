@charset "UTF-8";
@font-face {
  font-family: "Open Sans";
  src: url("../webfonts/OpenSans-Regular.eot");
  src: url("../webfonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../webfonts/OpenSans-Regular.woff2") format("woff2"), url("../webfonts/OpenSans-Regular.woff") format("woff"), url("../webfonts/OpenSans-Regular.ttf") format("truetype"), url("../webfonts/OpenSans-Regular.svg#OpenSans") format("svg");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("../webfonts/OpenSans-Italic.eot");
  src: url("../webfonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("../webfonts/OpenSans-Italic.woff2") format("woff2"), url("../webfonts/OpenSans-Italic.woff") format("woff"), url("../webfonts/OpenSans-Italic.ttf") format("truetype"), url("../webfonts/OpenSans-Italic.svg#OpenSans") format("svg");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("../webfonts/OpenSans-Bold.eot");
  src: url("../webfonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../webfonts/OpenSans-Bold.woff2") format("woff2"), url("../webfonts/OpenSans-Bold.woff") format("woff"), url("../webfonts/OpenSans-Bold.ttf") format("truetype"), url("../webfonts/OpenSans-Bold.svg#OpenSans") format("svg");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src: url("../webfonts/OpenSans-BoldItalic.eot");
  src: url("../webfonts/OpenSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../webfonts/OpenSans-BoldItalic.woff2") format("woff2"), url("../webfonts/OpenSans-BoldItalic.woff") format("woff"), url("../webfonts/OpenSans-BoldItalic.ttf") format("truetype"), url("../webfonts/OpenSans-BoldItalic.svg#OpenSans") format("svg");
  font-style: italic;
  font-weight: 700;
}
.auth-layout-wrap {
  background-image: url("../images/login-background.jpg");
}

.auth-content .auth-logo {
  position: relative;
}
.auth-content .auth-logo img {
  width: unset;
}
.auth-content .auth-logo div {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #f44034;
  font-size: 1.25em;
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
}
.auth-content .alert {
  margin-top: 1rem;
}

.layout-sidebar-large .main-header .menu-toggle {
  width: 36px;
  height: 36px;
  position: relative;
  margin-left: 36px;
  margin-right: 36px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.layout-sidebar-large .main-header .menu-toggle div {
  display: block;
  width: 36px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 4px 0;
  opacity: 1;
  transform-origin: left center;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.layout-sidebar-large .main-header .menu-toggle div:nth-child(2) {
  top: 10px;
}
.layout-sidebar-large .main-header .menu-toggle div:nth-child(3) {
  top: 20px;
}
.layout-sidebar-large .main-header .menu-toggle.open div:nth-child(1) {
  top: 0;
  left: 6px;
  transform: rotate(45deg);
}
.layout-sidebar-large .main-header .menu-toggle.open div:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.layout-sidebar-large .main-header .menu-toggle.open div:nth-child(3) {
  top: 26px;
  left: 6px;
  transform: rotate(-45deg);
}
.layout-sidebar-large .main-header .logo {
  width: 160px;
  position: relative;
}
.layout-sidebar-large .main-header .logo img {
  width: 80%;
  height: auto;
}
.layout-sidebar-large .main-header .logo div {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #f44034;
  font-size: 0.75em;
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
}
.layout-sidebar-large .main-header .header-notification {
  margin-right: 1em;
}
.layout-sidebar-large .main-header .header-notification > div {
  min-width: 240px;
  height: 52px;
  position: relative;
  background-color: #fefefe;
  border: 1px solid #dedede;
  border-left: 6px solid #dedede;
  border-radius: 10px;
  box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.2);
  color: #999999;
  font-size: 12px;
  padding: 6px 12px 8px 60px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.layout-sidebar-large .main-header .header-notification > div::before {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 6px;
  left: 10px;
  background-color: #cccccc;
  border-radius: 50%;
  content: "";
  color: #ffffff;
  font-family: "Font Awesome 5 Pro";
  font-size: 1.5em;
  font-weight: 900;
  line-height: 36px;
  text-align: center;
}
.layout-sidebar-large .main-header .header-notification > div.offline {
  border-left-color: #f44336;
  opacity: 1;
}
.layout-sidebar-large .main-header .header-notification > div.offline::before {
  background-color: #f44336;
  content: "";
}
.layout-sidebar-large .main-header .header-notification > div.online {
  border-left-color: #4caf50;
  opacity: 1;
}
.layout-sidebar-large .main-header .header-notification > div.online::before {
  background-color: #4caf50;
  content: "";
}
.layout-sidebar-large .main-header .header-notification > div > strong {
  display: block;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  color: #0b1c2d;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a.open {
  color: #00509E;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a.open .nav-icon {
  color: #00509E;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a.open .item-name {
  font-weight: 700;
}

.card .card-header .card-title {
  flex-grow: 1;
}
.card .card-header .card-title a {
  display: block;
}

.table .badge[class*=badge-square] {
  width: auto;
  min-width: 25px;
  border-radius: 0.2rem;
}

.input-group-dark input.form-control[readonly], .input-group-dark input.form-control[disabled] {
  border: 1px solid #13314e !important;
  background-color: #f3f8fc;
  box-shadow: none;
  color: #13314e;
}
.input-group-dark input.form-control[readonly]::-moz-selection, .input-group-dark input.form-control[readonly]::selection, .input-group-dark input.form-control[disabled]::-moz-selection, .input-group-dark input.form-control[disabled]::selection {
  background-color: #13314e;
  color: white;
}
.input-group-success input.form-control[readonly], .input-group-success input.form-control[disabled] {
  border: 1px solid green !important;
  background-color: honeydew;
  box-shadow: none;
  color: green;
}
.input-group-success input.form-control[readonly]::-moz-selection, .input-group-success input.form-control[readonly]::selection, .input-group-success input.form-control[disabled]::-moz-selection, .input-group-success input.form-control[disabled]::selection {
  background-color: green;
  color: white;
}
.input-group-danger input.form-control[readonly], .input-group-danger input.form-control[disabled] {
  border: 1px solid #f44034 !important;
  background-color: #fef1f0;
  box-shadow: none;
  color: #f44034;
}
.input-group-danger input.form-control[readonly]::-moz-selection, .input-group-danger input.form-control[readonly]::selection, .input-group-danger input.form-control[disabled]::-moz-selection, .input-group-danger input.form-control[disabled]::selection {
  background-color: #f44034;
  color: white;
}

.input-group-append .btn-dark {
  height: 100%;
}

.input-group-lg > .input-group-append > .btn.btn,
.input-group-lg > .input-group-prepend > .btn.btn {
  height: 100%;
  font-size: 1.01625rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}
.input-group-lg [type=text].form-control {
  height: auto;
  font-size: 1.01625rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}
.input-group-lg [type=text].form-control + .input-group-append .btn {
  border: 1px solid #173b5e;
}

.input-group-xl > .form-control-xl:not(textarea),
.input-group-xl > .custom-select,
.input-group-xl [type=text].form-control,
.input-group-xl [type=text].form-control.-xl {
  height: calc(1.5em + 2rem + 2px);
  font-size: 1.4rem;
}
.input-group-xl .input-group-prepend .btn,
.input-group-xl .input-group-append .btn {
  min-width: 68px;
  height: 100%;
}
.input-group-xl .input-group-prepend .btn .fa-lg,
.input-group-xl .input-group-append .btn .fa-lg {
  font-size: 3em;
}

.form-group label {
  font-size: 16px;
  color: #00509E;
}
.form-group label[for] {
  font-size: 14px;
  font-weight: 600;
}
.form-group label.required::after,
.form-group label > span.required::after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.75rem;
  font-weight: 900;
  color: #f44336;
  padding-left: 5px;
}
.form-group.required::before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.75rem;
  font-weight: 900;
  color: #f44336;
  line-height: 2rem;
  padding-right: 5px;
}
.form-group .mandatory[type=text], .form-group .mandatory[type=number] {
  border-color: #f44336;
}
.form-group .mandatory[for] .checkmark {
  border-color: #f44336;
}
.form-group select.mandatory {
  border-color: #f44336;
}
.form-group .password-strength {
  margin: 5px 0 10px 0;
}
.form-group .password-strength .progress {
  height: 7px;
  border: 1px solid #ced4da;
}

.checkbox.checkbox-lg {
  line-height: 4em;
  margin-bottom: 12px;
  padding-left: 58px;
}
.checkbox.checkbox-lg .checkmark {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
.checkbox.checkbox-lg .checkmark::after {
  width: 18px;
  height: 32px;
  bottom: 10px;
  left: 8px;
  right: 8px;
  border-width: 0 6px 6px 0;
}
.checkbox.checkbox-md {
  line-height: 2.5em;
  margin-bottom: 12px;
  padding-left: 44px;
}
.checkbox.checkbox-md .checkmark {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}
.checkbox.checkbox-md .checkmark::after {
  width: 12px;
  height: 24px;
  bottom: 4px;
  left: 2px;
  right: 2px;
  border-width: 0 6px 6px 0;
}
.checkbox-outline-primary.is-invalid .checkmark {
  border: 1px solid #f44336;
}
.checkbox.readonly {
  cursor: default;
}
.checkbox.readonly .checkmark {
  border: 1px solid #d5d5d5 !important;
}
.checkbox.readonly .checkmark::after {
  border-color: #d5d5d5 !important;
}
.checkbox.readonly span {
  color: #bbbbbb;
}
.checkbox.readonly span img {
  filter: grayscale(100%);
}

.radio.radio-lg {
  line-height: 4em;
  margin-bottom: 12px;
  padding-left: 58px;
}
.radio.radio-lg .checkmark {
  width: 50px;
  height: 50px;
}
.radio.radio-lg .checkmark::after {
  width: 32px;
  height: 32px;
}
.radio.radio-md {
  line-height: 2.5em;
  margin-bottom: 12px;
  padding-left: 44px;
}
.radio.radio-md .checkmark {
  width: 36px;
  height: 36px;
}
.radio.radio-md .checkmark::after {
  width: 20px;
  height: 20px;
}
.radio-outline-primary.is-invalid .checkmark {
  border: 1px solid #f44336;
}
.radio.readonly {
  cursor: default;
}
.radio.readonly .checkmark {
  border: 1px solid #d5d5d5 !important;
}
.radio.readonly .checkmark::after {
  background: #d5d5d5 !important;
}
.radio.readonly span {
  color: #bbbbbb;
}
.radio.readonly span img {
  filter: grayscale(100%);
}

.btn.disabled, .btn:disabled {
  background-color: #d5d5d5;
  border-color: #d5d5d5;
  color: #999999;
  cursor: not-allowed;
}
.btn.disabled:hover, .btn.disabled.btn-outline-success:hover, .btn:disabled:hover, .btn:disabled.btn-outline-success:hover {
  background: #d5d5d5;
  box-shadow: 0 8px 25px -8px #d5d5d5;
  border-color: #d5d5d5;
}
.btn.disabled:focus, .btn.disabled.btn-outline-success:focus, .btn:disabled:focus, .btn:disabled.btn-outline-success:focus {
  box-shadow: none;
  box-shadow: 0 8px 25px -8px #d5d5d5;
}
.btn.disabled.btn-increment, .btn.disabled.btn-decrement, .btn:disabled.btn-increment, .btn:disabled.btn-decrement {
  background: #bbbbbb;
  box-shadow: none;
  border-color: #bbbbbb;
  color: #ffffff;
  cursor: default;
  opacity: 1;
}
.btn.disabled.btn-increment:hover, .btn.disabled.btn-increment:active, .btn.disabled.btn-decrement:hover, .btn.disabled.btn-decrement:active, .btn:disabled.btn-increment:hover, .btn:disabled.btn-increment:active, .btn:disabled.btn-decrement:hover, .btn:disabled.btn-decrement:active {
  box-shadow: 0 8px 25px -8px #bbbbbb;
  cursor: default;
}
.btn.disabled > h2,
.btn.disabled > h4, .btn:disabled > h2,
.btn:disabled > h4 {
  color: #666666 !important;
}

.sync_success {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!-- Font Awesome Pro 5.15.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='%23008000' d='M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #008000;
  color: #008000 !important;
  padding-right: calc(1.5em + 0.75rem);
}
.sync_success > .checkmark {
  border-color: #008000;
}
.sync_success > .checkmark::after {
  border-color: #008000;
}
.sync_success.radio > .checkmark::after {
  background-color: #008000;
}
.sync_processing {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3C!-- Font Awesome Pro 5.15.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='%2300509e' d='M368 32h4c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h4c0 91.821 44.108 193.657 129.646 224C59.832 286.441 16 388.477 16 480h-4c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12h-4c0-91.821-44.108-193.657-129.646-224C324.168 225.559 368 123.523 368 32zM48 32h288c0 110.457-64.471 200-144 200S48 142.457 48 32zm288 448H48c0-110.457 64.471-200 144-200s144 89.543 144 200zm-66.34-333.088a141.625 141.625 0 0 1-6.774 8.739c-2.301 2.738-5.671 4.348-9.248 4.348H130.362c-3.576 0-6.947-1.61-9.248-4.348a142.319 142.319 0 0 1-6.774-8.739c-5.657-7.91.088-18.912 9.813-18.912h135.694c9.725 0 15.469 11.003 9.813 18.912zM98.379 416h187.243a12.01 12.01 0 0 1 11.602 8.903 199.464 199.464 0 0 1 2.059 8.43c1.664 7.522-4 14.667-11.704 14.667H96.422c-7.704 0-13.368-7.145-11.704-14.667.62-2.804 1.307-5.616 2.059-8.43A12.01 12.01 0 0 1 98.379 416z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #00509e;
  color: #00509e !important;
  padding-right: calc(1.5em + 0.75rem);
}
.sync_processing > .checkmark {
  border-color: #00509e;
}
.sync_processing > .checkmark::after {
  border-color: #00509e;
}
.sync_processing.radio > .checkmark::after {
  background-color: #00509e;
}
.sync_awaiting {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!-- Font Awesome Pro 5.15.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='%23ffc107' d='M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #ffc107;
  color: #ffc107 !important;
  padding-right: calc(1.5em + 0.75rem);
}
.sync_awaiting > .checkmark {
  border-color: #ffc107;
}
.sync_awaiting > .checkmark::after {
  border-color: #ffc107;
}
.sync_awaiting.radio > .checkmark::after {
  background-color: #ffc107;
}
.sync_error {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='%23f44336' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #f44336;
  color: #f44336 !important;
  padding-right: calc(1.5em + 0.75rem);
}
.sync_error > .checkmark {
  border-color: #f44336;
}
.sync_error > .checkmark::after {
  border-color: #f44336;
}
.sync_error.radio > .checkmark::after {
  background-color: #f44336;
}

.divider span.title {
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
}
.divider span:first-child, .divider span:last-child {
  height: 2px;
  background: #ced4da;
  margin-block: 1rem;
}

.alert ul li ul {
  padding-left: 1em;
}

.multiselect-native-select {
  display: block;
}
.multiselect-native-select > .btn-group {
  display: block;
}
.multiselect-native-select > .btn-group > .dropdown-toggle {
  text-align: left;
}
.multiselect-native-select > .btn-group > .dropdown-toggle::after {
  display: none;
}
.multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu {
  top: 36px !important;
  transform: none !important;
}
.multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu.show {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
}
.multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu.show > .dropdown-item {
  width: auto;
  min-width: 49%;
}
.multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu .dropdown-item.active, .multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu .dropdown-item:active {
  background-color: #fff4d3;
}

#file-upload.dropzone, #file-upload.dropzonejs,
#photo-upload.dropzone,
#photo-upload.dropzonejs {
  min-height: 300px;
}

.twitter-typeahead {
  display: block !important;
}
.twitter-typeahead .tt-menu {
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-top: 2px;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion {
  padding: 10px;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion:hover, .twitter-typeahead .tt-menu .tt-dataset .tt-suggestion.tt-cursor {
  background-color: #fff4d3;
}

.bootstrap-datetimepicker-widget.dropdown-menu, .bootstrap-datetimepicker-widget.usetwentyfour.dropdown-menu {
  width: 100%;
}
.bootstrap-datetimepicker-widget.dropdown-menu.float-right, .bootstrap-datetimepicker-widget.usetwentyfour.dropdown-menu.float-right {
  width: 50%;
}
.bootstrap-datetimepicker-widget table th, .bootstrap-datetimepicker-widget.usetwentyfour table th {
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
}
.bootstrap-datetimepicker-widget table td.day, .bootstrap-datetimepicker-widget table td.hour, .bootstrap-datetimepicker-widget.usetwentyfour table td.day, .bootstrap-datetimepicker-widget.usetwentyfour table td.hour {
  height: 40px;
  font-size: 1.2rem;
  line-height: 40px;
}
.bootstrap-datetimepicker-widget table td.minute, .bootstrap-datetimepicker-widget.usetwentyfour table td.minute {
  font-size: 1.2rem;
}
.bootstrap-datetimepicker-widget table td span, .bootstrap-datetimepicker-widget.usetwentyfour table td span {
  width: 72px;
  height: 72px;
  font-size: 1.4rem;
  line-height: 72px;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second, .bootstrap-datetimepicker-widget.usetwentyfour .timepicker-hour,
.bootstrap-datetimepicker-widget.usetwentyfour .timepicker-minute,
.bootstrap-datetimepicker-widget.usetwentyfour .timepicker-second {
  width: 72px;
  font-size: 1.4em;
}

table#datatable.dataTable {
  width: 100% !important;
}
table#datatable.dataTable > tbody > tr {
  cursor: pointer;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 1.6em;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin-top: 1em;
}
div.dataTables_wrapper div.dataTables_scrollBody {
  z-index: 0;
}

.btn-selectpicker {
  height: calc(1.5em + 0.75rem + 3px);
  background: #f8f9fa;
  border: 1px solid #ced4da;
}

.main-content .buttons .separator {
  width: 2px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0;
}
.main-content .filters-section {
  background-color: rgba(19, 49, 77, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px 15px;
}
.main-content .filters-section .filters-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.main-content .filters-section .filters-container h4 {
  margin: 0 3rem 0 1rem;
}
.main-content .filters-section .filters-container .form-group {
  margin-bottom: 0;
}
.main-content .filters-section .filters-container .form-group:last-child {
  width: 26vw;
}
.main-content .filters-section .filters-container .form-group:last-child .multiselect-native-select {
  width: 100%;
}
.main-content .filters-section .filters-container .form-group:last-child .multiselect-native-select > .btn-group > .custom-select {
  height: calc(2em + 0.5rem + 2px);
  font-size: 1rem;
}
.main-content .filters-section .filters-container .form-group:last-child .multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu {
  top: 44px !important;
}
.main-content .filters-section .filters-container .form-group:last-child .multiselect-native-select > .btn-group > .multiselect-container.dropdown-menu.show > .dropdown-item {
  width: 49%;
}
.main-content .filters-section .filters-container .form-group.filter-range input[type=text] {
  min-width: 220px;
}
.main-content .filters-section .filters-container .form-group.filter-state .multiselect-native-select .multiselect-container .multiselect-option:last-child {
  width: 100% !important;
  border-top: 1px solid #ced4da;
  padding-top: 0.8rem;
}
.main-content .table th,
.main-content .table td {
  vertical-align: middle;
}
.main-content .table th form button.btn-lg,
.main-content .table td form button.btn-lg {
  padding: 0.5rem 0.75rem;
}
.main-content .form-control {
  height: calc(1.5em + 0.75rem + 3px);
}
.main-content .form-control.file-no-browse {
  z-index: -1;
}
.main-content .form-control[readonly], .main-content .form-control[disabled] {
  border: 1px solid #ced4da !important;
  color: #bbbbbb;
  cursor: default;
}
.main-content .form-control[readonly] + .input-group-append > button, .main-content .form-control[disabled] + .input-group-append > button {
  background-color: #bbbbbb;
}
.main-content .form-control[readonly] + .input-group-append > button:hover, .main-content .form-control[disabled] + .input-group-append > button:hover {
  background: #bbbbbb;
  box-shadow: 0 8px 25px -8px #bbbbbb;
  border-color: #bbbbbb;
  cursor: default;
}
.main-content .form-control[readonly] + .input-group-append > button:focus, .main-content .form-control[disabled] + .input-group-append > button:focus {
  box-shadow: none;
  box-shadow: 0 8px 25px -8px #bbbbbb;
  cursor: default;
}
.main-content textarea.form-control {
  height: auto;
  resize: none;
}
.main-content .logo_image {
  max-height: 50px;
}
.main-content .logo_thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 1rem;
  padding: 1rem;
}
.main-content .logo_thumbnail > img {
  max-height: 100px;
}
.main-content .logo_upload .krajee-default.file-preview-frame {
  display: flex;
  align-items: center;
  float: none;
}
.main-content .logo_upload .krajee-default.file-preview-frame .kv-file-content {
  height: 100%;
}
.main-content .logo_upload .krajee-default.file-preview-frame .file-thumbnail-footer {
  display: flex;
  flex-grow: 1;
  height: 100%;
}
.main-content .logo_upload .krajee-default .file-footer-caption {
  flex-grow: 1;
}
.main-content .logo_upload .krajee-default .file-upload-indicator,
.main-content .logo_upload .krajee-default .file-drag-handle {
  display: none;
}
.main-content .theme-explorer .file_upload .file-drop-zone {
  display: flex;
  flex-wrap: wrap;
}
.main-content .theme-explorer .file_upload .file-drop-zone > * {
  width: 100%;
}
.main-content .theme-explorer .file_upload .file-drop-zone .krajee-default.file-preview-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #ddd;
  margin: 2px 0;
}
.main-content .theme-explorer .file_upload .file-drop-zone .krajee-default.file-preview-frame .kv-file-content {
  width: 160px;
  height: 80px;
  padding: 5px;
  text-align: left;
}
.main-content .theme-explorer .file_upload .file-drop-zone .krajee-default.file-preview-frame .kv-file-content .kv-preview-data[data] {
  cursor: pointer;
}
.main-content .theme-explorer .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-footer-caption {
  font-size: 12px;
}
.main-content .theme-explorer .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-footer-caption .file-size-info samp {
  font-size: 10px;
}
.main-content .theme-explorer .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-thumb-progress {
  position: relative;
  top: auto;
  margin-bottom: 10px;
}
.main-content .theme-explorer-fa .file_upload .file-drop-zone .krajee-default.file-preview-frame .kv-file-content .kv-preview-data[data] {
  cursor: pointer;
}
.main-content .theme-explorer-fa .file_upload .file-drop-zone .krajee-default.file-preview-frame .kv-file-content .kv-preview-data[src] {
  width: inherit !important;
  height: inherit !important;
  object-fit: cover;
  cursor: pointer;
}
.main-content .theme-explorer-fa .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-footer-caption {
  font-size: 12px;
}
.main-content .theme-explorer-fa .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-footer-caption .file-size-info samp {
  font-size: 10px;
}
.main-content .theme-explorer-fa .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-footer-buttons .kv-file-download {
  display: none;
}
.main-content .theme-explorer-fa .file_upload .file-drop-zone .krajee-default.file-preview-frame .file-thumb-progress {
  position: relative;
  top: auto;
  margin-bottom: 10px;
}
.main-content input.fileupload[readonly] {
  display: none;
}
.main-content .report div[class^=col-] {
  margin-top: 10px;
  margin-bottom: 20px;
}
.main-content .report h5 {
  font-size: 14px;
  font-weight: bold;
  color: #00509E;
}
.main-content .report strong {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 1px;
}
.main-content .report strong p {
  margin-top: 10px;
  margin-bottom: 0;
}

.signature {
  position: relative;
}
.signature canvas {
  width: 100%;
  height: 480px;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  color: #13314d;
}
.signature .btn-fullscreen {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 10px;
  right: 72px;
  z-index: 1;
}
.signature .btn-clear {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.signature .btn-save {
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 15px;
  right: 10px;
  z-index: 1;
}
.signature.fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.signature.fullscreen canvas {
  height: 100%;
}

.timer {
  width: 150px;
}
.timer .countdown {
  display: flex;
  align-items: center;
  font-weight: normal;
}
.timer .countdown .item {
  display: inline-block;
  position: relative;
  background: #2A2A2A;
  background: linear-gradient(#2A2A2A, #000);
  border-radius: 4px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: #FFFFFF;
  text-align: center;
  vertical-align: bottom;
  margin: 0;
  padding: 0 6px;
  overflow: hidden;
  /*&:after
  {
  	display: block;
  	width: 100%;
  	height: 1px;
  	position: absolute;
  	top: 50%;
  	left: 0;
  	border-top: 2px solid #111;
  	content: '';
  }*/
}
.timer .countdown .separator {
  display: inline-block;
  width: 10px;
}
.timer.timer-lg {
  width: 100%;
}
.timer.timer-lg .countdown .item {
  border-radius: 6px;
  font-size: 42px;
  line-height: 52px;
  padding: 10px 20px;
}
.timer.warning .countdown .item {
  color: #ffc107;
}
.timer.danger .countdown .item {
  color: #f44336;
}

.static_timer > div {
  display: inline-block;
  position: relative;
  background: #2A2A2A;
  background: linear-gradient(#2A2A2A, #000);
  border-radius: 4px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: #FFFFFF;
  text-align: center;
  vertical-align: bottom;
  margin: 0;
  padding: 0 6px;
  /*&:after
  {
  	display: block;
  	width: 100%;
  	height: 1px;
  	position: absolute;
  	top: 50%;
  	left: 0;
  	border-top: 2px solid #111;
  	content: '';
  }*/
}
.static_timer > div + div {
  margin-left: 10px;
}
.static_timer > div + div::before {
  width: 10px;
  height: 100%;
  position: absolute;
  left: -10px;
  content: ":";
  color: var(--dark);
  font-size: 2.0325rem;
  font-weight: 300;
}
.static_timer.timer-lg > div {
  border-radius: 6px;
  font-size: 42px;
  line-height: 52px;
  padding: 10px 20px;
}
.static_timer.warning > div {
  color: #ffc107;
}
.static_timer.danger > div {
  color: #f44336;
}

#toast-container .toast {
  background-color: #030303;
}
#toast-container .toast-success {
  background-color: #51a351;
}
#toast-container .toast-error {
  background-color: #bd362f;
}
#toast-container .toast-info {
  background-color: #2f96b4;
}
#toast-container .toast-warning {
  background-color: #f89406;
}

.popover-md {
  min-width: 360px;
}

.invalid-feedback.right,
.invalid-tooltip.right {
  left: auto;
  right: 0;
}
.invalid-feedback > ul,
.invalid-tooltip > ul {
  margin: 0.5em 0;
  padding-left: 1em;
}

div.form-control.tagging {
  height: auto !important;
}
div.form-control.tagging .type-zone {
  background-color: transparent;
}

[w-15],
.w-15 {
  width: 15% !important;
}

[w-action],
.w-action {
  width: 180px !important;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
.actions > a.btn-lg,
.actions > button.btn-lg {
  padding: 0.5rem 0.7rem;
}

.add-to-home {
  position: absolute;
  top: 1px;
  left: 1px;
}

@media only screen and (max-width: 1080px) {
  .main-content .filters-section .filters-container {
    justify-content: start;
    flex-wrap: wrap;
  }
  .main-content .filters-section .filters-container > div {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  .main-content .filters-section .filters-container > div:not(.buttons) {
    justify-content: space-between;
    margin-top: 0;
  }
  .main-content .filters-section .filters-container > div:not(.buttons) .form-group:last-child {
    width: 360px;
  }
}
@media only screen and (max-width: 810px) {
  .three-columns {
    flex-wrap: wrap;
  }
  .three-columns label {
    width: calc(100% / 3 - 1.5rem);
  }

  .main-content .filters-section .filters-container > div:not(.buttons) {
    display: block !important;
  }
  .main-content .filters-section .filters-container > div:not(.buttons) > * {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .main-content .filters-section .filters-container > div:not(.buttons) > *:last-child {
    margin-bottom: 0;
  }
  .main-content .filters-section .filters-container > div:not(.buttons) h4 {
    margin: 0 0 20px 0;
    text-align: center;
  }
  .main-content .filters-section .filters-container > div:not(.buttons) .form-group:last-child {
    width: auto;
  }

  .input-group-dark, .input-group-success, .input-group-danger {
    flex-wrap: nowrap;
  }
  .input-group-dark input.form-control[readonly], .input-group-dark input.form-control[disabled], .input-group-success input.form-control[readonly], .input-group-success input.form-control[disabled], .input-group-danger input.form-control[readonly], .input-group-danger input.form-control[disabled] {
    min-width: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .auth-content .row.justify-content-between {
    justify-content: center !important;
  }
  .auth-content .row.justify-content-between > .auth-logo {
    width: 100%;
    text-align: center;
  }
  .auth-content .row.justify-content-between > .language {
    margin-bottom: 1.25em;
  }
}